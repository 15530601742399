import React from "react";

const Spinner = ({ height = 50, width = 50, color = "#2B2BA0" }) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={`w-[${width}px] h-[${height}px] border-4 border-[${color}] border-t-transparent rounded-full animate-spin`}
      />
    </div>
  );
};

export default Spinner;