exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-orders-order-id-index-tsx": () => import("./../../../src/pages/orders/[orderId]/index.tsx" /* webpackChunkName: "component---src-pages-orders-order-id-index-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-stores-components-store-selection-state-tsx": () => import("./../../../src/pages/stores/components/StoreSelectionState.tsx" /* webpackChunkName: "component---src-pages-stores-components-store-selection-state-tsx" */),
  "component---src-pages-stores-index-tsx": () => import("./../../../src/pages/stores/index.tsx" /* webpackChunkName: "component---src-pages-stores-index-tsx" */)
}

