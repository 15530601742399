import { useEffect, useState } from 'react';

/*
  Use Only in Functional Component
  Because it uses useState & useEffect Hooks
*/

const Breakpoint = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return {
    windowWidth,
    windowHeight,
    isMobile: windowWidth <= 640,
    isTablet: windowWidth > 640 && windowWidth <= 768
  };
};

export default Breakpoint;
