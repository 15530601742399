import React from "react";
import { useSelector } from "react-redux";
import Svg from "../svg";
import { RootState } from "../store";
import CustomImage from "../components/custom-image";

declare global {
  interface Window {
    fcWidget: any;
  }
}

const Header = () => {
  const storeData = useSelector((state: RootState) => state.storeData);
  const { storeProfile } = storeData;

  return (
    <div
      className="flex items-center justify-between bg-white
        shadow-sm rounded-b-[20px] px-3 h-[60px] sticky top-0"
    >
     <div
      className="flex items-center gap-1 md:gap-2 w-[50%]"
     >
      <div className="">
        <CustomImage
          imageType="PRODUCT"
          imageUrl={storeProfile?.data?.storeType?.image}
          imageAlt="logo"
          imageStyle="h-[40px] w-[40px]"
        />
      </div>
      <div className="max-w-[80%] md:max-w-[90%] items-center">
        <span className={`font-black text-ellipsis line-clamp-1 leading-7 text-[22px]`}>
          {storeProfile?.data?.displayName}
        </span>
        <span className="text-[#808080] text-xs font-[400px] text-ellipsis line-clamp-1">
          {`${storeProfile?.data?.locality || '--'}, ${storeProfile?.data?.city || '--'}`}
        </span>
      </div>
     </div>

      {/* Support Contact */}
      <div className="md:flex items-center gap-1">
        {/* C */}
        <p className="text-[#595959] text-sm font-[14px] flex items-center gap-1">
          <Svg name="call-icon" />
          Need help? Call us
        </p>
        <span className="text-[#2B2BA0] text-sm font-black flex justify-center">
          {/* // update with actual number */}
          +91 81049 72097
        </span>
      </div>
    </div>
  )
}

export default Header;
