import React from "react";
import { Link, navigate } from 'gatsby';
import { useLocation } from "@reach/router";
import Svg from "../svg";
import authStorage from "../utils/authStorage";

const Sidebar = () => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  const handleLogout = () => {
    authStorage.removeAuthentication();
    window.location.replace('/login');
  };

  const navBarList = [
    {
      name: "Orders",
      path: "/",
      icon: (active: boolean) => (
        <Svg name="orders-icon" stroke={active ? "#2B2BA0" : "#595959"} />
      ),
    },
    {
      name: "Products",
      path: "/products/",
      icon: (active: boolean) => (
        <Svg name="products-icon" stroke={active ? "#2B2BA0" : "#595959"} />
      ),
    },
  ];

  return (
    <div className="h-full w-full flex flex-col justify-between">
      {/* Top Section */}
      <div>
        {/* Logo Section */}
        <div className="flex items-center mt-8 mb-6">
          <div className="">
            {/* @ts-ignore */}
            <Svg name="ll-login-logo" fill1="#2B2BA0" fill2="#2B2BA0" fill3="#2B2BA0" fill4="white" />
          </div>
        </div>

        {/* Navigation Links */}
        <nav className="flex flex-col gap-6 px-4">
          {navBarList.map((item) => {
            const isLinkActive = isActive(item.path);
            return (
              <Link
                key={item.name}
                to={item.path}
                className={`flex items-center gap-3`}
              >
                {item.icon(isLinkActive)}
                <span
                  className={`text-sm hover:text-blue-800 ${
                    isLinkActive ? 'text-blue-800 font-bold' : 'text-gray-700 font-light'
                  }`}
                >
                  {item.name}
                </span>
              </Link>
            );
          })}
        </nav>
      </div>

      {/* Bottom Section */}
      <div className="flex flex-col gap-4 mb-6 px-4">
        {/* Change Shop */}
        <button className="flex items-center gap-3" onClick={() => navigate("/stores")}>
          <Svg name="store-switch-icon" />
          <span className="text-sm font-light text-gray-700 hover:text-blue-800">Change Shop</span>
        </button>
        {/* Logout */}
        <button
          className="flex items-center gap-3"
          onClick={handleLogout}
        >
          <Svg name="logout-icon" />
          <span className="text-sm font-light text-gray-700 hover:text-blue-800">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
