import React from "react";
import "./src/styles/global.css"
import Wrapper from "./src/wrapper";
import { store } from './src/store';
import { Provider } from "react-redux";
import { isFullyAuthenticated } from "./src/utils/authentication";

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
     {element}
    </Provider>
  );
};

export const wrapPageElement = ({ element }) => {
  return isFullyAuthenticated() ? (
    <Wrapper>
      {element}
    </Wrapper>
  ) : (
  <>
    {element}
  </>
  );
};
