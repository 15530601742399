import authStorage from "../utils/authStorage";

export const setHeaders = (headers: Record<string, string> = {}): Record<string, string> => ({
  'Content-Type': 'application/json',
  'Client-Code': 'bulbasaur',
  'Accept-Language': 'en',
  'Client-Version': '600',
  'Client-User': 'retailer',
  'authorization': authStorage.getToken() ?? '',
  'x-access-token': authStorage.getAccessToken() ?? '',
  ...headers,
});

export const setAuthorizationHeaders = (): { 'retailer-id'?: string } => {
  const retailerId = authStorage.getRetailerId();
  return retailerId ? { 'retailer-id': retailerId } : {};
};

export const getParams = (parameters: Record<string, unknown>): URLSearchParams => {
  const searchParams = new URLSearchParams();
  Object.entries(parameters).forEach(([key, value]) => {
    if (
      (value || value === 0) &&
      value !== 'null' &&
      value !== 'undefined'
    ) {
      searchParams.append(key, String(value));
    }
  });
  return searchParams;
};

export const apiUrls = {
  legacyBaseUrl: process.env.GATSBY_LEGACY_BASE_URL,
  OOBaseUrl: process.env.GATSBY_OO_BASE_URL,
  newOOBaseUrl: process.env.GATSBY_NEW_OO_BASE_URL,
  imageBaseUrl: process.env.GATSBY_IMAGE_BASE_URL,
}
