import React from "react"
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import breakpoint from "../utils/breakpoints";
const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { isMobile } = breakpoint();
  return (
    <div className="w-full h-full bg-[#EBEDFF] font-lato">
      {/* Side Bar */}
      <div className="flex">
        <div
          className={`hidden md:block w-1/4 lg:w-1/6 h-[100vh] shadow-xl p-[16px] bg-white`}
        >
          <Sidebar />
        </div>
        {/* Main Content */}
        <div className={`${isMobile ? "w-full" : "w-3/4 lg:w-5/6"} mx-2`}>
          {/* Header */}
          <div className="">
            <Header />
          </div>
          {/* Component */}
          <div
            className={`bg-[#FFFFFF] my-2.5 rounded-[20px] w-full overflow-y-scroll scrollbar-hide
              ${isMobile ? "h-[calc(100vh-142px)]" : "h-[calc(100vh-80px)]"}`}
            >
            {children}
          </div>
        </div>
      </div>
      {/* Footer */}
      {
        isMobile && (
          <div className="border">
            <Footer />
          </div>
        )
      }
    </div>
  )
}
export default Wrapper