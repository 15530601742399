// Define types for token, store, and other data
type TokenType = string | null;
type StoreType = Record<string, any> | null;
type EpochType = number | null;
type StoreUserType = Record<string, any> | null;

interface StorageHandler {
  setToken: (token: string) => void;
  getToken: () => TokenType;
  setStore: (store: string) => void;
  getStore: () => StoreType;
  setStoreUser: (user: StoreUserType) => void;
  getStoreUser: () => StoreUserType;
  setStoreUserId: (id: string) => void;
  getStoreUserId: () => TokenType;
  setRetailerId: (id: string) => void;
  getRetailerId: () => TokenType;
  setAccessToken: (token: string) => void;
  getAccessToken: () => TokenType;
  setRefreshToken: (token: string) => void;
  getRefreshToken: () => TokenType;
  setLastAccessTokenUpdatedAtEpoch: (epoch: number) => void;
  getLastAccessTokenUpdatedAtEpoch: () => EpochType;
  removeAuthentication: () => void;
}

// Constants for key management and security
const AUTH_TOKEN_KEY = 'app_auth_token';
const STORE_KEY = 'app_store';
const STORE_USER_KEY = 'app_store_user';
const STORE_USER_ID_KEY = 'app_store_user_id';
const STORE_RETAILER_ID_KEY = 'app_retailer_user_id';
const ACCESS_TOKEN_KEY = 'app_access_token';
const REFRESH_TOKEN_KEY = 'app_refresh_token';
const LAST_TOKEN_UPDATE_KEY = 'app_last_token_update';
const TOKEN_PREFIX = 'Token';

const authStorage: StorageHandler = {
  setToken: (token: string): void => {
    try {
      const formattedToken = `${TOKEN_PREFIX} ${token}`;
      localStorage.setItem(AUTH_TOKEN_KEY, formattedToken);
      sessionStorage.setItem(AUTH_TOKEN_KEY, formattedToken);
    } catch (error) {
      console.error('Token storage failed:', error);
    }
  },

  getToken: (): TokenType => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN_KEY) ?? sessionStorage.getItem(AUTH_TOKEN_KEY);
      return token ? token.replace(`${TOKEN_PREFIX} `, `${TOKEN_PREFIX} `) : null;
    } catch (error) {
      console.error('Token retrieval failed:', error);
      return null;
    }
  },

  setStore: (store: any): void => {
    try {
      localStorage.setItem(STORE_KEY, JSON.stringify(store));
    } catch (error) {
      console.error('Store storage failed:', error);
    }
  },

  getStore: (): StoreType => {
    try {
      const store = localStorage.getItem(STORE_KEY);
      return store ? JSON.parse(store) : null;
    } catch (error) {
      console.error('Store user retrieval failed:', error);
      return null;
    }
  },

  setStoreUser: (user: StoreUserType): void => {
    try {
      localStorage.setItem(STORE_USER_KEY, JSON.stringify(user));
    } catch (error) {
      console.error('Store user storage failed:', error);
    }
  },

  getStoreUser: (): StoreUserType => {
    try {
      const user = localStorage.getItem(STORE_USER_KEY);
      return user ? JSON.parse(user) : null;
    } catch (error) {
      console.error('Store user retrieval failed:', error);
      return null;
    }
  },

  setStoreUserId: (id: string): void => {
    try {
      localStorage.setItem(STORE_USER_ID_KEY, id);
    } catch (error) {
      console.error('Store user ID storage failed:', error);
    }
  },

  getStoreUserId: (): TokenType => {
    try {
      return localStorage.getItem(STORE_USER_ID_KEY);
    } catch (error) {
      console.error('Store user ID retrieval failed:', error);
      return null;
    }
  },

  setRetailerId: (id: string): void => {
    try {
      localStorage.setItem(STORE_RETAILER_ID_KEY, id);
    } catch (error) {
      console.error('Store user ID storage failed:', error);
    }
  },

  getRetailerId: (): TokenType => {
    try {
      return localStorage.getItem(STORE_RETAILER_ID_KEY);
    } catch (error) {
      console.error('Store user ID retrieval failed:', error);
      return null;
    }
  },

  setAccessToken: (token: string): void => {
    try {
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
    } catch (error) {
      console.error('Access token storage failed:', error);
    }
  },

  getAccessToken: (): TokenType => {
    try {
      return localStorage.getItem(ACCESS_TOKEN_KEY);
    } catch (error) {
      console.error('Access token retrieval failed:', error);
      return null;
    }
  },

  setRefreshToken: (token: string): void => {
    try {
      localStorage.setItem(REFRESH_TOKEN_KEY, token);
    } catch (error) {
      console.error('Refresh token storage failed:', error);
    }
  },

  getRefreshToken: (): TokenType => {
    try {
      return localStorage.getItem(REFRESH_TOKEN_KEY);
    } catch (error) {
      console.error('Refresh token retrieval failed:', error);
      return null;
    }
  },

  setLastAccessTokenUpdatedAtEpoch: (epoch: number): void => {
    try {
      localStorage.setItem(LAST_TOKEN_UPDATE_KEY, epoch.toString());
    } catch (error) {
      console.error('Last token update storage failed:', error);
    }
  },

  getLastAccessTokenUpdatedAtEpoch: (): EpochType => {
    try {
      const epoch = localStorage.getItem(LAST_TOKEN_UPDATE_KEY);
      return epoch ? Number(epoch) : null;
    } catch (error) {
      console.error('Last token update retrieval failed:', error);
      return null;
    }
  },

  removeAuthentication: (): void => {
    try {
      // Remove all relevant keys
      localStorage.clear();
      sessionStorage.clear();
    } catch (error) {
      console.error('Token removal failed:', error);
    }
  },
};

export default authStorage;
