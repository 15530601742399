import axios from 'axios';
import * as urls from './urls';


export const getStrings = (method: string) => {
  return (
    axios({
      method,
      url: urls.getStrings(),
    })
  );
};


const BASE_URL = "https://homedeliverybackend-release.mpaani.com";

export const getOrderDetail = async (method: string) => {
  try {
    const response = await axios({
      method,
      url: `${BASE_URL}/order/59045917/retailer/details`,
      headers: {
        "Host": "api.com",
        "Accept": "application/json",
        "Authorization": "Bearer your-token-here",
        "client-code": "bulbasaur",
        "Client-User": "retailer",
        "retailer-id": "321086",
        "x-access-token":
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MzIxMDg2LCJ0eXBlIjoiQUNDRVNTIiwicm9sIjoiUkVUQUlMRVIiLCJleHAiOjE3Mzg1NjE5Njh9.nxNem3UFPu-Un7ZxsYsxCKflFdQvW8iI93Fa6G8Vcq0",
      },
    });

    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};


export const getDummy = (method: string) => {
  return (
    axios({
      method,
      url: urls.getDummy(),
    })
  );
};
