import Axios, { CancelToken } from 'axios';
import { apiUrls, setAuthorizationHeaders, setHeaders } from '../utils';
import authStorage from '../../utils/authStorage';

const { newOOBaseUrl } = apiUrls;

interface AuthApiResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
}

interface AuthApis {
  sendOTP: (cancelToken: CancelToken, data?: Record<string, unknown>) => Promise<AuthApiResponse>;
  logIn: (cancelToken: CancelToken, data?: Record<string, unknown>) => Promise<AuthApiResponse>;
  refreshToken: (cancelToken: CancelToken, data?: Record<string, unknown>) => Promise<AuthApiResponse>;
  storeProfile: (cancelToken: CancelToken, storeCodeParam?: string) => Promise<AuthApiResponse>;
}

const storeId = authStorage.getStoreUserId();

const authApis: AuthApis = {
  sendOTP: (cancelToken, data = {}) => {
    const sendOtp = `${newOOBaseUrl}auth/send-otp`;
    return Axios({
      url: sendOtp,
      method: 'POST',
      headers: setHeaders(),
      data,
      cancelToken,
    });
  },

  logIn: (cancelToken, data = {}) => {
    const logIn = `${newOOBaseUrl}auth/login`;
    return Axios({
      url: logIn,
      method: 'POST',
      headers: setHeaders(),
      data,
      cancelToken,
    });
  },

  refreshToken: (cancelToken, data = {}) => {
    const refreshTokenUrl = `${newOOBaseUrl}auth/refresh-token`;
    return Axios({
      url: refreshTokenUrl,
      method: 'POST',
      headers: setHeaders(),
      data,
      cancelToken,
    });
  },

  storeProfile: (cancelToken, storeCodeParam = '') => {
    const storeProfile = `${newOOBaseUrl}retailer/store-details/${storeCodeParam ?? storeId}`;
    return Axios({
      url: storeProfile,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders()),
      cancelToken,
    });
  },
};

export default authApis;
