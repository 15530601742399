import React, { useState, useMemo } from 'react';

import loaderAnim from './images/image-loader.gif';
import defaultCustomPlaceHolder from './images/default-custom-placeholder.png';
import defaultCustomItemPlaceHolder from './images/default-custom-item-placeholder.png';
import defaultOffersPlaceHolder from './images/default-offers-placeholder.png';
import defaultProductPlaceHolder from './images/default-product-placeholder.png';

// Error fallback for image
const errorImageFallback = (event: React.SyntheticEvent<HTMLImageElement, Event>, fallbackImageSrc: string) => {
  event.currentTarget.src = fallbackImageSrc;
};

interface CustomImageProps {
  imageType: 'PRODUCT' | 'OFFER' | 'CUSTOM_OFFER' | 'CATEGORY' | 'CUSTOM_ITEM';
  imageUrl: string;
  imageStyle?: string;
  imageAlt?: string;
  imageTitle?: string;
}

const CustomImage: React.FC<CustomImageProps> = ({
  imageType,
  imageUrl,
  imageStyle = '',
  imageAlt = '',
  imageTitle = '',
}) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Use useMemo to determine the fallback image based on the imageType
  const fallbackImage = useMemo(() => {
    switch (imageType) {
      case 'PRODUCT':
      case 'CATEGORY':
        return defaultProductPlaceHolder;
      case 'OFFER':
        return defaultOffersPlaceHolder;
      case 'CUSTOM_OFFER':
        return defaultCustomPlaceHolder;
      case 'CUSTOM_ITEM':
        return defaultCustomItemPlaceHolder;
      default:
        return defaultCustomPlaceHolder;
    }
  }, [imageType]);

  return (
    <img
      className={imageStyle}
      alt={imageAlt}
      title={imageTitle}
      src={isImageLoading ? loaderAnim : imageUrl}
      loading="lazy"
      onError={(event) => errorImageFallback(event, fallbackImage)}
      onLoad={() => setIsImageLoading(false)}
    />
  );
};

export default (CustomImage);
