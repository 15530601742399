import React from "react";

const Footer = () => {
  return (
    <div
      className="h-[60px] sticky bottom-0 bg-white"
    >
      <h1>Footer Tab</h1>
    </div>
  )
}

export default Footer;
