// src/utils/auth.ts

/* eslint-disable camelcase */
import authApis from '../apis/auth';
import authStorage from './authStorage';
const {
    getAccessToken, getStoreUser, getStoreUserId, getStore,
    getLastAccessTokenUpdatedAtEpoch, getRefreshToken, removeAuthentication,
    setAccessToken, setLastAccessTokenUpdatedAtEpoch, setRefreshToken,
} = authStorage;

export const ACCESS_TOKEN_TTL = 23 * 60 * 60 * 1000;

const allStores = getStore();
const storeUser = getStoreUser();
const accessToken = getAccessToken();
const refreshToken = getRefreshToken();
const storeUserId = getStoreUserId();

// Function to check if the user is logged in
export const isLoggedIn = (): boolean => {
  return !!(allStores && accessToken && refreshToken);
};

// Function to check if the user is fully authenticated
export const isFullyAuthenticated = (): boolean => {
  return !!(allStores && storeUser && accessToken && refreshToken && storeUserId);
};

// Function to log out the user
export const logoutUser = (): void => {
  removeAuthentication();
  window.location.replace('/login');
};

// Function to update access and refresh tokens, with retry logic
export const updateAccessTokenAndRefreshToken = (retryCount = 3): void => {
  const lastAccessTokenUpdatedAtEpoch = getLastAccessTokenUpdatedAtEpoch();
  
  if (
    lastAccessTokenUpdatedAtEpoch &&
    (new Date().getTime() - lastAccessTokenUpdatedAtEpoch) > ACCESS_TOKEN_TTL
  ) {
    const refresh_token = getRefreshToken() ?? '';
    const access_token = getAccessToken() ?? '';

    // @ts-ignore
    authApis.refreshToken(null, {
      refresh_token,
      access_token,
      role: 'RETAILER',
    })
      .then((res) => {
        const tokens = res.data;
        setAccessToken(tokens.data.access_token);
        setRefreshToken(tokens.data.refresh_token);
        setLastAccessTokenUpdatedAtEpoch(new Date().getTime());

        setTimeout(() => {
          updateAccessTokenAndRefreshToken();
        }, ACCESS_TOKEN_TTL);
      })
      .catch((error) => {
        if (error?.response) {
          if (error.response.status === 401) {
            logoutUser();
          } else {
            setTimeout(() => {
              if (retryCount > 1) {
                updateAccessTokenAndRefreshToken(retryCount - 1);
              } else {
                logoutUser();
              }
            }, 2000);
          }
        }
      });
  } else if (!lastAccessTokenUpdatedAtEpoch) {
    logoutUser();
  } else {
    const updateTokenAfter = (lastAccessTokenUpdatedAtEpoch + ACCESS_TOKEN_TTL) - new Date().getTime();
    setTimeout(() => {
      updateAccessTokenAndRefreshToken();
    }, updateTokenAfter);
  }
};
