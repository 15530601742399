import React, { forwardRef, lazy, Suspense, useMemo } from 'react';
import Spinner from '../components/Spinner';

interface SvgProps {
  name: string
  width?: number
  height?: number
  fill?: string
  stroke?: string
}

const Svg = (props: SvgProps, ref: React.Ref<unknown>) => {
  const { name, ...rest } = props;

  // Dynamically import the svg based on the name prop
  const DynamicSvg = useMemo(() => {
    return lazy(() =>
      import(`./${name}`)
        .catch(() => {
          return { default: () => <></> }; // Return an empty component if the svg can't be loaded
        })
    );
  }, [name]); // Only re-trigger the lazy load when the name prop changes

  return (
    <Suspense fallback={<div> <Spinner height={16} width={16}/> </div>}>
      <DynamicSvg {...rest} ref={ref} />
    </Suspense>
  );
};

export default forwardRef(Svg);
