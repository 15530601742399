import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import orderReducer from './slices/orderSlice';
import stringSlice from './slices/stringsSlice';
import mainSlices from './slices/mainSlices';

const reducers = combineReducers({
  strings: stringSlice,
  storeData: mainSlices
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  timeout: 0,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch