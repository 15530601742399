import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authStorage from '../../utils/authStorage';
import authApis from '../../apis/auth';

export const fetchStoreProfile = createAsyncThunk(
  'Fetching Store Profile',
  async (storeCode: string) => {
  // @ts-ignore
  const response = await authApis.storeProfile(null, storeCode);
  return response.data?.data;
});

export const mainSlices = createSlice({
  name: 'storeData',
  initialState: {
    stores: authStorage.getStore() || [],
    storeProfile: {
        loading: false,
        error: false,
        data: authStorage.getStoreUser() || null,
    },
    storeId: authStorage.getStoreUserId() ?? '',
  },
  reducers: {
    updateStoreProfile: (state, action) => {
      state.storeProfile.data = action.payload;
      state.storeId = action.payload.code;
      state.storeProfile.loading = false;
      state.storeProfile.error = false;
      authStorage.setStoreUser(action.payload);
      authStorage.setStoreUserId(action.payload.code);
    },
    updateStores: (state, action) => {
      state.stores = action.payload;
    }
  },
  extraReducers: (builder) => {
      // For Fetching
      builder
        .addCase(fetchStoreProfile.pending, (state) => {
          state.storeProfile.loading = true;
        })
        .addCase(fetchStoreProfile.fulfilled, (state, action) => {
          state.storeProfile.loading = false;
          state.storeProfile.data = action.payload;
          authStorage.setStoreUser(action.payload);
          authStorage.setStoreUserId(action.payload.code);
        })
        .addCase(fetchStoreProfile.rejected, (state, action) => {
          state.storeProfile.loading = false;
          state.storeProfile.error = false;
        });
    },
})

export default mainSlices.reducer;
