import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getStrings } from '../../apis'

// Async thunk for fetching posts from an API
export const fetchStrings = createAsyncThunk(
  'Fetching Strings',
  async () => {
  const response = await getStrings('GET');
  return response.data;
});

export const postStrings = createAsyncThunk(
  'Post Strings',
  async () => {
  const response = await getStrings('GET');
  return response.data;
});

export const stringSlice = createSlice({
  name: 'strings',
  initialState: {
    loading: false,
    error: '',
    strings: null,
  },
  reducers: { },

  extraReducers: (builder) => {
    // for get call
    builder
      .addCase(fetchStrings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStrings.fulfilled, (state, action) => {
        state.loading = false;
        state.strings = action.payload; // Update state with API data
      })
      .addCase(fetchStrings.rejected, (state, action) => {
        state.loading = false;
        state.error = 'Retry'; // Capture error message
      });
    // for post call
    builder
    .addCase(postStrings.pending, (state) => {
      state.loading = true;
    })
    .addCase(postStrings.fulfilled, (state, action) => {
      state.loading = false;
      state.strings = action.payload; // Update state with API data
    })
    .addCase(postStrings.rejected, (state, action) => {
      state.loading = true;
      state.error = 'Retry'; // Capture error message
    });
  },
})

export default stringSlice.reducer
